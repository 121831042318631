import React, { useState, useEffect, useRef } from 'react';
import { IoSend } from 'react-icons/io5';
import ThreeDotLoader from '../components/ThreeDotLoader';
import { toast } from 'react-toastify';
import Linkify from 'react-linkify';
import { clearLocalStorage, deleteWithToken, getLocalStorage, getWithoutToken, getWithToken, patchWithToken, postWithoutToken, postWithToken } from '../API/Api';
import { FaEdit, FaSave } from 'react-icons/fa';
import TextareaAutosize from 'react-textarea-autosize';
import CHATBOT_ICON from '../assets/icons/ChirpfloIcon-V2.0/chatbot-icon.svg';
import SIDEBAR_BOX_IMG from '../assets/icons/ChirpfloIcon-V2.0/logo-icon.svg';
import CHIRPFLO_LOGO from '../assets/icons/ChirpfloIcon-V2.0/chirpflo-icon.svg';
import { BiTrash } from 'react-icons/bi';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import ScrollToBottom from './ScrollToBottom';

const formatText = (text) => {
  return text.split(/\*\*(.*?)\*\*/g).map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part));
};

const EditChatBox = (props) => {
  const [chatData, setChatData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState('');
  const [apiTokenData, setApiTokenData] = useState('');
  const [editIndex, setEditIndex] = useState('');
  const [editValue, setEditValue] = useState('');
  const [openChatbox, setOpenChatbox] = useState(false);
  const [widgetDetails, setWidgetDetails] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [hideLeadButtons, setHideLeadButtons] = useState(false);
  const [hideLeadClosingButtons, setHideLeadClosingButtons] = useState(false);
  const [companyDetails, setCompanyDetails] = useState('');
  const [customDisabled, setCustomDisabled] = useState(false);
  const [retrainPopup, setRetrainPopup] = useState(false);
  const [welBubblePopup, setWelBubblePopup] = useState(false);
  const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
  const [welcomeDetails, setWelcomeDetails] = useState({
    welcome_msg: null,
    lead_choice: null,
    lead_status: false,
    generate_lead_choice: null,
  });

  const initialFormValue = {
    name: '',
    email: '',
    phone_number: '',
  };
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(initialFormValue);
  const [showFormButton, setShowFormButton] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);

  const { name, email, phone_number } = formData;

  useEffect(() => {
    let debounce = null;
    let token = getLocalStorage('apiToken');
    if (token) {
      if (debounce != null) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => {
        setApiTokenData(JSON.parse(token));
        getCompanyDetails(JSON.parse(token));
        getWelcomeMsg(JSON.parse(token));
        getWidgetDetails(JSON.parse(token));
      }, 500);
    }
    return () => {
      clearTimeout(debounce);
    };
  }, [chatData, props]);

  useEffect(() => {
    if (widgetDetails?.buble_timer_count && widgetDetails?.buble_active_status) {
      if (widgetDetails.status && widgetDetails.buble_active_status) {
        setTimeout(() => {
          setWelBubblePopup(false);
        }, widgetDetails?.buble_timer_count * 1000);
      }
    } else {
      setWelBubblePopup(true);
    }
  }, [widgetDetails]);

  useEffect(() => {
    setSessionId(generateRandomSessionId());
  }, []);

  const generateRandomSessionId = () => {
    const numbers = '0123456789';
    const length = 8;
    let randomId = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * numbers.length);
      randomId += numbers.charAt(randomIndex);
    }
    return randomId;
  };

  const getWidgetDetails = (token) => {
    getWithToken('Webchat_widgetAPI/', token)
      .then((response) => {
        if (response.status === 200) {
          setWidgetDetails(response?.data);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const getWelcomeMsg = (token) => {
    getWithToken('wellcomeSmsApi/', token)
      .then((response) => {
        if (response.status === 200) {
          setWelcomeDetails({
            welcome_msg: response?.data?.welcome_msg,
            lead_choice: response?.data?.lead_choice,
            lead_status: response?.data?.status,
            generate_lead_choice: response?.data?.generate_lead_choice,
          });
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };
  const getCompanyDetails = (token) => {
    getWithToken('company_base/', token)
      .then((response) => {
        if (response.status === 201) {
          setCompanyDetails(response?.data);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const getShowRecommendation = (id) => {
    getWithoutToken(`lead_answers_retrieve/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setShowRecommendation(response?.data);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.trim().length > 0) {
      let newObj = { prmt: values };
      chatData.push(newObj);
      let body = {
        prompt: values,
        session_id: sessionId,
      };
      setValues('');
      setLoader(true);
      setShowRecommendation(null);
      chatPostApi(body);
    } else {
      toast.dismiss();
      toast.error('Message is blank.');
      setLoader(false);
    }
  };
  const chatPostApi = (body) => {
    setCustomDisabled(true);
    postWithToken('ChatGPTAPI_', apiTokenData, body)
      .then((response) => {
        if (response.status === 200) {
          const tempChatData = [...chatData];
          const { data } = response;
          data.forEach((element) => {
            let tempData = {
              choice: element,
              text: element.text,
              id: element.id,
            };
            if (!welcomeDetails?.lead_choice) {
              setHideLeadButtons(false);
            }
            if (tempData?.choice?.closing_chatbot === true) {
              setTimeout(() => {
                setOpenChatbox(false);
              }, 2000);
            }
            if (tempData?.choice?.closing_lead_choice !== undefined) {
              setHideLeadClosingButtons(false);
            } else {
              setCustomDisabled(true);
            }

            if (element?.lead_form) {
              setShowForm(true);
            }
            if (element?.recommendation) {
              getShowRecommendation(element?.recommendation);
            }

            tempChatData.push(tempData);
          });
          setCustomDisabled(false);
          setChatData(tempChatData);
          setLoader(false);
        } else if (response.status === 400) {
          toast.error(response.message);
          setLoader(false);
        } else if (response.status === 403) {
          toast.error(response?.message);
          setLoader(false);
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleChoice = (e, Data) => {
    setHideLeadButtons(true);
    setHideLeadClosingButtons(true);
    let newObj = { prmt: Data };
    chatData.push(newObj);
    let body = {
      prompt: Data,
      session_id: sessionId,
    };
    setLoader(true);
    setOpenChatbox(true);
    chatPostApi(body);
  };
  const handelEditChatData = (newData, index) => {
    setEditIndex(newData.id);
    setEditValue(newData.text);
  };

  const sendToChild = (data) => {
    props.passChildData(data);
  };

  const handelSaveChatData = (newData) => {
    let obj = {
      text: editValue,
    };
    if (editValue !== '') {
      patchWithToken('Chat_logAPI/' + newData.id, apiTokenData, obj)
        .then((response) => {
          if (response.status === 200) {
            toast.success('Value updated');
            setEditIndex('');
            const tempArray = [...chatData];
            tempArray.forEach((element, index) => {
              if (element.id === newData.id) {
                tempArray[index].text = editValue;
              }
            });
            sendToChild(true);
            setLoader(false);
          } else if (response.code === 'token_not_valid') {
            clearLocalStorage();
          }
        })
        .catch((error) => {
          // toast.error('Something went wrong');
        });
    } else {
      toast.dismiss();
      toast.error('Empty text field');
    }
  };

  const hanldeClearData = () => {
    deleteWithToken('ChatGPTAPI_', apiTokenData)
      .then((response) => {
        if (response.status === 200) {
          setHideLeadButtons(false);
          setChatData([]);
          setShowForm(false);
          setShowRecommendation(null);
          setShowFormButton(false);
          setDeleteConfirmPopup(false);
          setRetrainPopup(false);
          setSessionId(generateRandomSessionId());
          toast.success(response?.message);
        } else if (response.code === 'token_not_valid') {
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const handleFirstButtonClick = (e, data) => {
    setShowForm(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: name,
      email: email,
      phone_number: phone_number,
      recipient_id: sessionId,
    };
    postWithoutToken('Create_LeadInformationAPI?company=' + companyDetails?.id, payload)
      .then((response) => {
        if (response.status === 201) {
          setFormData(initialFormValue);
          setShowForm(false);

          handleChoice(e, `${formData.name} ${formData.email} ${formData.phone_number}`);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  function MessageComponent({ message }) {
    const lines = message.split('\n');

    return (
      <>
        {lines.map((line, index) => (
          <p key={index} className='m-0 inter-regular font16'>
            {line}
          </p>
        ))}
      </>
    );
  }

  return (
    <>
      <div className={`chatbox-container ${openChatbox ? 'widgetOpen' : ''}`}>
        <div className='chatbox-header flex-column justify-content-center'>
          <div
            className='close-arrow'
            onClick={() => {
              setOpenChatbox(false);
              setWelBubblePopup(true);
              setDeleteConfirmPopup(false);
            }}
          >
            <MdOutlineKeyboardArrowRight />
          </div>
          <div className='chatbox-header-icon'>
            <img src={CHIRPFLO_LOGO} alt='chatbox-logo' />
          </div>
          <div className='chatbox-header-text mt-2'>
            <h3 className='opensans-bold mb-0'>{companyDetails?.bot_name?.length > 15 ? companyDetails?.bot_name.substring(0, 15) + '...' : companyDetails?.bot_name}</h3>
          </div>
        </div>
        <div className='custom-hr m-0'></div>
        <div className='chatbox-body p-3'>
          <div className={`chatbox-body-box ${!hideLeadButtons && welcomeDetails?.lead_choice != null && 'pt-5 mb-0'}`}>
            <>
              {welcomeDetails?.welcome_msg !== '' && (
                <>
                  {welcomeDetails?.lead_status === true ? (
                    <div className='w-100'>
                      <div className={`chatbot-welcome-body  editdata-chatbox ${hideLeadButtons && welcomeDetails?.lead_choice != null ? 'p-0' : 'EditChatbot-withBoxShadow'}`}>
                        {!hideLeadButtons && (
                          <>
                            {!showForm && (
                              <div
                                className='d-flex justify-content-center mb-4'
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  borderRadius: '50%',
                                  background: '#3e4074',
                                  borderRadius: '50%',
                                  position: 'absolute',
                                  top: 0,
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              >
                                <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' className='w-100' style={{ height: 'auto', objectFit: 'cover' }} />
                              </div>
                            )}
                            <div className={`d-flex align-items-center ${showForm && 'mt-3'}`}>
                              {showForm && (
                                <div className='chatbox-user-icon me-3'>
                                  <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                                </div>
                              )}
                              <div className={` ${showForm ? 'chatbox-user-replytext' : 'welcome-text text-white mt-5'}`}>
                                <p className='m-0 opensans-bold text-center font16'>{welcomeDetails?.welcome_msg}</p>
                              </div>
                            </div>
                          </>
                        )}
                        {!hideLeadButtons && welcomeDetails?.lead_choice != null && (
                          <div className='d-flex flex-column align-items-center w-100'>
                            {welcomeDetails?.lead_choice.map((choice, index) => (
                              <React.Fragment key={index}>
                                <button
                                  className='btn btn-submit-login inter-regular font14'
                                  style={{
                                    background: index === 0 ? '#726ca9' : '#ffffff',
                                    color: index === 0 ? '#ffffff' : '#726ca9',
                                    border: '2px solid #726ca9',
                                    borderRadius: '34px',
                                    height: '40px',
                                    marginTop: '1rem',
                                    maxWidth: 'unset',
                                  }}
                                  onClick={
                                    index === 0
                                      ? (e) => handleChoice(e, choice)
                                      : (e) => {
                                          handleChoice(e, choice);
                                          setShowFormButton(true);
                                        }
                                  }
                                >
                                  {choice}
                                </button>
                                {/* {index !== welcomeDetails.lead_choice.length - 1 && <span className='opensans-regular my-2 text-white hr-line'>Or</span>} */}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex align-items-center mt-3'>
                      <div className='custom-chat-anstext'>
                        <div className='chatbox-user-icon mb-2'>
                          <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                        </div>
                        <p className='m-0'>{welcomeDetails?.welcome_msg}</p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {chatData.length > 0 &&
                chatData?.map((obj, index) => {
                  return (
                    <>
                      <div className='mb-2' key={index}>
                        {obj.prmt && (
                          <div className='d-flex align-items-center justify-content-end mt-3'>
                            <div className='chatbox-user-quetext'>
                              <p className='m-0'>{obj?.prmt}</p>
                            </div>
                          </div>
                        )}
                        <div>
                          {obj?.text && (
                            <div className='d-flex  editdata-chatbox mt-3'>
                              <div className='d-flex align-items-center editdata-chatbox '>
                                <div className='chatbox-user-replytext'>
                                  {/* <div className='chatbox-user-icon mb-2'>
                                    <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                                  </div> */}

                                  <div className='d-flex align-items-center mb-2'>
                                    <div className='chatbox-user-icon me-2'>
                                      <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                                    </div>
                                    <span className='opensans-bold font14' style={{ color: '#ffffff' }}>
                                      {companyDetails?.bot_name}
                                    </span>
                                  </div>
                                  {editIndex !== obj.id ? (
                                    <p className='m-0' style={{ whiteSpace: 'pre-line' }}>
                                      <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                          <a target='blank' href={decoratedHref} key={key}>
                                            {decoratedText}
                                          </a>
                                        )}
                                      >
                                        {formatText(obj?.text)}
                                      </Linkify>
                                    </p>
                                  ) : (
                                    <>
                                      <div className='d-flex align-items-center'>
                                        <TextareaAutosize className='py-2' placeholder='Enter category here...' onChange={(e) => setEditValue(e.target.value)}>
                                          {obj.text}
                                        </TextareaAutosize>
                                        <div className='knowledge-pair-icon editchat-box-icon'>
                                          <button className='btn' type='button' onClick={() => handelSaveChatData(obj, index)}>
                                            <FaSave />
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className='knowledge-pair-icon '>
                                  <button className='btn' type='button' onClick={() => handelEditChatData(obj, index)}>
                                    <FaEdit />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!hideLeadClosingButtons && obj?.choice?.closing_lead_choice !== undefined && !showForm && (
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                          {obj?.choice?.closing_lead_choice?.length > 0 &&
                            obj?.choice?.closing_lead_choice?.map((choice, index) => (
                              <button
                                key={index}
                                className={`btn btn-submit-login preview-btn-style rounded bg-themePurple border-themePurple ${index === 0 ? 'me-3' : ''}`}
                                onClick={
                                  index === 0
                                    ? (e) => handleFirstButtonClick(e, choice)
                                    : (e) => {
                                        handleChoice(e, choice);
                                        setShowFormButton(true);
                                        setShowForm(false);
                                      }
                                }
                              >
                                {choice}
                              </button>
                            ))}
                        </div>
                      )}
                    </>
                  );
                })}
              {loader ? (
                <>
                  <div className='custom-chat-anstext d-inline-block mt-3' style={{ width: 'fit-content' }}>
                    <div className='d-flex align-items-center'>
                      <div className='widget-user-icon position-relative'>
                        <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                      <ThreeDotLoader />
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}

              {showRecommendation && (
                <>
                  {showRecommendation?.map((recommendation, index) => (
                    <div key={index} className='chat-group'>
                      <button
                        className='btn chat-group-btn'
                        onClick={(e) => {
                          handleChoice(e, recommendation?.answer);
                          setShowRecommendation(null);
                        }}
                      >
                        {recommendation?.answer}
                      </button>
                    </div>
                  ))}
                </>
              )}

              {showForm && (
                <div className='user-box-wrapper position-relative'>
                  <div className='user-box'>
                    <div className='user-box-img'>
                      <div className='widget-user-icon'>
                        <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                    </div>

                    <form id='form' onSubmit={handleFormSubmit}>
                      <div className='mb-3'>
                        <label className='opensans-medium mb-1'>Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='name'
                          id='name'
                          required
                          autoComplete='off'
                          placeholder='Enter your name'
                          value={name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                      </div>
                      <div className='mb-3'>
                        <label className='opensans-medium mb-1'>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          name='email'
                          required
                          autoComplete='off'
                          placeholder='Enter your email'
                          pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'
                          value={email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='opensans-medium mb-1'>Phone</label>
                        <input
                          type='number'
                          className='form-control'
                          name='phone_number'
                          required
                          autoComplete='off'
                          placeholder='Enter your number'
                          maxLength='10'
                          value={phone_number}
                          onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                        />
                      </div>

                      <input
                        id='submit'
                        type='submit'
                        disabled={name === '' || phone_number === '' || email === ''}
                        style={{
                          opacity: name === '' || phone_number === '' || email === '' ? 0.3 : 1,
                          cursor: name === '' || phone_number === '' || email === '' ? 'not-allowed' : 'pointer',
                        }}
                      />
                    </form>
                  </div>
                </div>
              )}
              <ScrollToBottom scrollChatData={chatData} scrollLoader={loader} showForm={showForm} showRecommendation={showRecommendation} />
            </>
          </div>
        </div>

        {deleteConfirmPopup && (
          <div className='delete-confirmation-popup'>
            <div className='confirmation-box'>
              <div className='logo-wrapper'>
                <img src={CHIRPFLO_LOGO} alt='chatbot-icon' className='chatbot-logo' />
              </div>
              <p className='delete-confirmation-text'>Are you sure you want to permanently delete and reset the chat?</p>
              <div className='button-group'>
                <button className='btn-confirm' onClick={(e) => hanldeClearData(e)}>
                  Yes!
                </button>
                <button className='btn-cancel' onClick={() => setDeleteConfirmPopup(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className='p-3'>
          {(hideLeadButtons && hideLeadClosingButtons) || !welcomeDetails?.lead_status ? (
            <>
              {showFormButton && (
                <div className='d-flex align-items-center justify-content-end mb-2'>
                  <button
                    className='btn text-white inter-regular font16 w-100'
                    onClick={() => {
                      setShowForm(true);
                    }}
                    style={{ background: '#3e4074', borderRadius: '12px' }}
                  >
                    {welcomeDetails?.generate_lead_choice || 'Generate Lead'}
                  </button>
                </div>
              )}

              <form className='chatbox-group d-flex justify-content-between align-items-center'>
                <div className='chatbox-input-group'>
                  <button type='button' className='btn refresh-btn' disabled={customDisabled} onClick={() => setDeleteConfirmPopup(true)}>
                    <BiTrash className='icon-refresh' />
                  </button>
                </div>
                <input
                  type='text'
                  className='form-control chat-input'
                  placeholder='Type Message..'
                  name='message'
                  value={values}
                  disabled={showForm}
                  onChange={(e) => setValues(e.target.value)}
                  autoComplete='off'
                />
                <div className='chatbox-input-group'>
                  <button type='submit' className='btn send-btn' disabled={customDisabled || showForm} onClick={handleSubmit}>
                    <IoSend className='icon-send ms-1' />
                  </button>
                </div>
              </form>
            </>
          ) : null}
        </div>
        <div className='custom-chat-footer'>
          <h3 className='opensans-regular me-2 mb-0'>Created with</h3>
          <span className='opensans-bold lead-summary-color mb-0 '>Chirpflo</span>
        </div>
      </div>

      {welcomeDetails?.welcome_msg != null && !openChatbox && (
        <>
          {!welBubblePopup && widgetDetails?.buble_active_status && (
            <div className='welcome-popup d-flex flex-column align-items-end'>
              <div className={`chatbot-welcome-body align-items-end ${hideLeadButtons && welcomeDetails?.lead_choice != null && 'p-0'}`}>
                {!hideLeadButtons && (
                  <div className={`d-flex align-items-center justify-content-end ${showForm && 'mt-3'}`}>
                    {showForm && (
                      <div className='chatbox-user-icon me-3'>
                        <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                      </div>
                    )}
                    <div
                      className={`welcome-text-box custom-chat-anstext text-white text-start ${welcomeDetails?.lead_status && 'p-4'}`}
                      style={{ boxShadow: '0px 0px 15px 10px rgba(0, 0, 0, 0.1), 1px 0px 6px 5px rgba(0, 0, 0, 0.1)' }}
                    >
                      <div className='bubble-close-icon' style={{ background: '#69dfe1' }} onClick={() => setWelBubblePopup(true)}>
                        <IoMdClose style={{ fontSize: '20px', color: '#000000' }} />
                      </div>
                      <div className={`d-flex align-items-center  ${!welcomeDetails?.lead_status ? 'justify-content-between' : 'justify-content-center mt-3'}`}>
                        <div className={`${!welcomeDetails?.lead_status ? 'text-start' : 'text-center'}`}>
                          <MessageComponent message={welcomeDetails?.welcome_msg} />
                        </div>
                        <div
                          className={`chatbox-user-icon ${!welcomeDetails?.lead_status ? 'cursor-pointer' : 'user-box-img bg-themeBotPurple p-0'}`}
                          style={{ width: '55px', height: '55px', minWidth: '55px', minHeight: '55px', border: 'none' }}
                          onClick={() => !welcomeDetails?.lead_status && setOpenChatbox(!openChatbox)}
                        >
                          <img src={SIDEBAR_BOX_IMG} alt='chatbot-icon' />
                        </div>
                      </div>
                      {!hideLeadButtons && welcomeDetails?.lead_choice != null && (
                        <div className='d-flex align-items-center flex-column buttonWrap'>
                          {welcomeDetails?.lead_choice.map((choice, index) => (
                            <React.Fragment key={index}>
                              <button
                                className='btn btn-submit-bubble inter-regular font14'
                                style={{
                                  marginTop: '1rem',
                                  background: index === 0 ? '#69DFE1' : '#3e4074',
                                  border: `2px solid #69DFE1`,
                                  borderRadius: '34px',
                                  color: index === 0 ? '#000000' : '#69DFE1',
                                  height: '40px',
                                  maxWidth: '100%',
                                }}
                                onClick={
                                  index === 0
                                    ? (e) => handleChoice(e, choice)
                                    : (e) => {
                                        handleChoice(e, choice);
                                        setShowFormButton(true);
                                      }
                                }
                              >
                                {choice}
                              </button>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {welBubblePopup && (
            <div className='chatbot-popup d-flex flex-column align-items-end'>
              <div className='d-flex align-items-center'>
                <p className='mb-0 me-4 font16 text-white opensans-regular'>Test your agent here</p>
                <div className='chatbot-popup-icon' onClick={() => setOpenChatbox(!openChatbox)}>
                  <img src={CHATBOT_ICON} alt='chatbot-icon' />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {retrainPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <div className={'popup-head text-center'}>
              <h4 className='font18 color-theme-darkLightblue opensans-regular'>Oops just gotta quickly re -train your agent. </h4>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn success-btn text-capitalize me-2'
                style={{ background: 'linear-gradient(to right, #579df1, #69dfe1)', border: 'none' }}
                onClick={() => hanldeClearData()}
              >
                Retrain
              </button>
              <button className='btn danger-btn  text-capitalize' data-dismiss='modal' onClick={() => setRetrainPopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditChatBox;
